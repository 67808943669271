import React, { useState } from "react";
import {
  AppBar,
  Button,
  Grid,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, navigate } from "gatsby";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useWindowScroll, useWindowSize } from "react-use";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Language } from "@mui/icons-material";

const Navbar = () => {
  const { y } = useWindowScroll();
  const theme = useTheme();
  const { width } = useWindowSize();
  const { languages, changeLanguage, language } = useI18next();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const positionY =
    typeof window !== "undefined" &&
    window.location.pathname.split("/").filter((x) => x).length === 0
      ? 930
      : 430;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      elevation={0}
      color="transparent"
      position="fixed"
      component="nav"
      sx={{
        height: y <= positionY ? 170 : 65,

        backgroundColor: y >= positionY && "rgba(247, 247, 243, 0.45)",
        boxShadow: y >= positionY && "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: y >= positionY && "blur(5px)",
        transition: "all ease 0.3s",
        border: "1px solid transparent",
      }}
    >
      <Toolbar sx={{ height: "inherit" }}>
        <Grid container maxWidth={1520} margin="0 auto" paddingY={1}>
          <Grid item sm={2} xs={6}>
            <Link to="/">
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101221025-297e333f.png"
                width="100%"
                style={{
                  maxWidth: width < 600 ? 120 : y <= positionY ? 250 : 100,
                  objectFit: "contain",
                }}
                alt="Logo"
              />
            </Link>
          </Grid>

          <Grid
            item
            xs
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
            display={{ xs: "none", lg: "flex" }}
          >
            <Grid item>
              <Link
                to="/"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Inicio"
                  : language === "en"
                  ? "Home"
                  : null}
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/festival-del-chocolate"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Festival del Chocolate"
                  : language === "en"
                  ? "Chocolate Festival"
                  : null}
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/historia-del-chocolate"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Historia del Chocolate"
                  : language === "en"
                  ? "History of Chocolate"
                  : null}
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/concursos"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Concursos"
                  : language === "en"
                  ? "Contests"
                  : null}
              </Link>
            </Grid>

            <Grid item>
              <Link
                to="/programa"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Programa"
                  : language === "en"
                  ? "Program"
                  : null}
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/ven-al-festival"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Ven al festival"
                  : language === "en"
                  ? "Come to the festival"
                  : null}
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/chile-chontal"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                Chile Chontal
              </Link>
            </Grid>
            {/*   <Grid item>
              <Link
                to="/kids-zone"
                style={{ color: theme.palette.primary.main, fontWeight: "500" }}
              >
                {language === "es"
                  ? "Kids zone"
                  : language === "en"
                  ? "Kids zone"
                  : null}
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Button
                onClick={() => navigate("/estado-solicitud")}
                variant="outlined"
              >
                {language === "es"
                  ? "Estado solicitud"
                  : language === "en"
                  ? "Application status"
                  : null}
              </Button>
            </Grid> */}

            {/* <Grid item>
              <Button
                onClick={() => navigate("/solicitud-expositores")}
                variant="contained"
              >
                {language === "es"
                  ? "Registro expositor"
                  : language === "en"
                  ? "Join as exhibitor"
                  : null}
              </Button>
            </Grid> */}

            {/* <Grid item>
              <Link to="/" style={{ color: "#6c3f37" }}>
                Festival del Chocolate
              </Link>
            </Grid>
            
            <Grid item>
              <Link to="/" style={{ color: "#6c3f37" }}>
                Tours y Experiencias
              </Link>
            </Grid>
            <Grid item>
              <Link to="/" style={{ color: "#6c3f37" }}>
                Más Información
              </Link>
            </Grid> */}
            <Grid item>
              <div style={{ display: "flex", gap: "4px" }}>
                <Language htmlColor={theme.palette.primary.main} />
                <Select size="small" variant="standard" value={language}>
                  {languages.map((lng) => (
                    <MenuItem
                      value={lng}
                      key={lng}
                      onClick={(e) => {
                        e.preventDefault();
                        changeLanguage(lng);
                      }}
                    >
                      <Typography color={theme.palette.primary.main}>
                        {lng === "es" ? "ES" : lng === "en" ? "EN" : lng}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs
            container
            justifyContent="flex-end"
            alignItems="center"
            display={{ xs: "flex", lg: "none" }}
          >
            <MenuRoundedIcon
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              htmlColor={theme.palette.primary.main}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => navigate("/")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Inicio"
                  : language === "en"
                  ? "Home"
                  : null}
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/festival-del-chocolate")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Festival del Chocolate"
                  : language === "en"
                  ? "Chocolate Festival"
                  : null}
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/historia-del-chocolate")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Historia del Chocolate"
                  : language === "en"
                  ? "History of Chocolate"
                  : null}
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/concursos")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Concursos"
                  : language === "en"
                  ? "Contests"
                  : null}
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/programa")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Programa"
                  : language === "en"
                  ? "Program"
                  : null}
              </MenuItem>

              <MenuItem
                onClick={() => navigate("/ven-al-festival")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Ven al festival"
                  : language === "en"
                  ? "Come to the festival"
                  : null}
              </MenuItem>
              <MenuItem
                onClick={() => navigate("/chile-chontal")}
                style={{ color: theme.palette.primary.main }}
              >
                Chile Chontal
              </MenuItem>
              {/* 
              <MenuItem
                onClick={() => navigate("/kids-zone")}
                style={{ color: theme.palette.primary.main }}
              >
                {language === "es"
                  ? "Kids zone"
                  : language === "en"
                  ? "Kids zone"
                  : null}
              </MenuItem> */}

              {/*  <MenuItem>
                <Grid item>
                  <Button
                    onClick={() => navigate("/estado-solicitud")}
                    variant="outlined"
                  >
                    {language === "es"
                      ? "Estado solicitud"
                      : language === "en"
                      ? "Application status"
                      : null}
                  </Button>
                </Grid>
              </MenuItem> */}
              {/* <MenuItem>
                <Grid item>
                  <Button
                    onClick={() => navigate("/solicitud-expositores")}
                    variant="contained"
                  >
                    {language === "es"
                      ? "Registro expositor"
                      : language === "en"
                      ? "Join as exhibitor"
                      : null}
                  </Button>
                </Grid>
              </MenuItem> */}
              <MenuItem>
                <div style={{ display: "flex", gap: "4px" }}>
                  <Language htmlColor={theme.palette.primary.main} />
                  <Select size="small" variant="standard" value={language}>
                    {languages.map((lng) => (
                      <MenuItem
                        value={lng}
                        key={lng}
                        onClick={(e) => {
                          e.preventDefault();
                          changeLanguage(lng);
                        }}
                      >
                        <Typography color={theme.palette.primary.main}>
                          {lng === "es" ? "ES" : lng === "en" ? "EN" : lng}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

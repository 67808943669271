import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import Seo from "../assets/components/seo";
import Navbar from "../assets/components/Kids/Navbar";
import Footer from "../assets/components/Footer";

const KidsZone = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
      }}
    >
      <Seo
        title="Programa - Festival del Chocolate"
        description="Conoce el programa de actividades del 12vo Festival del Chocolate"
      />
      <Navbar />
      <Grid
        container
        paddingY={12}
        sx={{
          backgroundImage:
            "url(https://imagenturistica.tabasco.gob.mx/i.php?/upload/2023/10/31/20231031151554-2b9ae60d-xx.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionY: { xs: "100px", md: "170px" },
          backgroundColor: "white",
        }}
      >
        {/* Banner principal Desktop */}
        <Grid
          item
          xs={12}
          container
          alignItems="flex-end"
          minHeight="90vh"
          display={{ xs: "none", md: "flex" }}
        >
          <img
            src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2023/11/01/20231101230226-08e0dc24-xx.png"
            width="100%"
            height="100%"
            style={{
              maxWidth: 1920,
              objectFit: "contain",
              position: "absolute",
            }}
            alt="Banner principal"
          />
          <Grid
            item
            container
            justifyContent="center"
            xs={8}
            marginBottom={18}
            spacing={1}
          >
            <Grid item zIndex={99} component="a" href={`#teobromas`}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101232224-f0a72339.png"
                width="100%"
                alt="boton rojo"
                style={{
                  maxHeight: 80,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid item zIndex={99} component="a" href={`#preguntaleateo`}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101232224-ab4f6bb9.png"
                width="100%"
                alt="boton rojo"
                style={{
                  maxHeight: 80,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              zIndex={99}
              component="a"
              href={`#historiadelchocolate`}
            >
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101232225-010244e8.png"
                width="100%"
                alt="boton rojo"
                style={{
                  maxHeight: 80,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={4} visibility="hidden"></Grid>
        </Grid>
        {/* Banner principal Desktop */}

        {/* Banner principal Mobile */}
        <Grid
          item
          container
          xs={12}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
          alignItems="center"
          marginTop={4}
        >
          <Grid item>
            <img
              src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/06/20231106014138-cd5b982b.png"
              width="100%"
              height="100%"
              style={{ maxWidth: 280, objectFit: "cover" }}
              alt="Banner principal"
            />
          </Grid>

          <Grid item>
            <img
              src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/11/06/20231106014137-aba6b66e-me.png"
              width="100%"
              height="100%"
              style={{ maxWidth: 400, objectFit: "cover" }}
              alt="Banner principal"
            />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            marginBottom={18}
            spacing={1}
          >
            <Grid item zIndex={99}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101232224-f0a72339.png"
                width="100%"
                alt="boton rojo"
                onClick={() => alert("holi")}
                style={{
                  maxHeight: 40,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid item zIndex={99}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101232224-ab4f6bb9.png"
                width="100%"
                alt="boton rojo"
                onClick={() => alert("holi")}
                style={{
                  maxHeight: 40,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid item xs={12} zIndex={99}>
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/01/20231101232225-010244e8.png"
                width="100%"
                alt="boton rojo"
                onClick={() => alert("holi")}
                style={{
                  maxHeight: 40,
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Banner principal Mobile */}

        {/* Banner principal kids */}
        <Grid
          item
          xs={12}
          container
          id="teobromas"
          minHeight="90vh"
          alignItems="center"
          position="relative"
          justifyContent={{ xs: "center", lg: "end" }}
        >
          <Grid item xs={5} display={{ xs: "none", lg: "flex" }}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/11/06/20231106015704-b6d2a370-me.png"
              width="100%"
              alt="boton rojo"
              onClick={() => alert("holi")}
              style={{
                maxWidth: 800,
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={7}
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} textAlign="center">
              <img
                src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/06/20231106021959-b8543dfc.png"
                width="100%"
                height="100%"
                style={{
                  objectFit: "contain",
                  maxWidth: 500,
                }}
                alt="Banner principal"
              />
            </Grid>
            <Grid item>
              <iframe
                width="430"
                height="265"
                style={{ borderWidth: 25, borderColor: "#39C0CC" }}
                src="https://www.youtube.com/embed/381SzypmGV8?si=IMHcvcGBnGP31hhz"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
            <Grid item>
              <iframe
                width="430"
                height="265"
                style={{ borderWidth: 25, borderColor: "#39C0CC" }}
                src="https://www.youtube.com/embed/B5TlJfNwHyM?si=nVREJm9mfQLemrLG"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
            <Grid item>
              <iframe
                width="430"
                height="265"
                style={{ borderWidth: 25, borderColor: "#39C0CC" }}
                src="https://www.youtube.com/embed/CGpceCS3DnQ?si=gwNicWhbdBsD1m0V"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
            <Grid item>
              <iframe
                width="430"
                height="265"
                style={{ borderWidth: 25, borderColor: "#39C0CC" }}
                src="https://www.youtube.com/embed/eHKbmf9qZnk?si=S7yFGIn_KvYJMzqq"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </Grid>
          </Grid>
          <img
            src="https://imagenturistica.tabasco.gob.mx/_data/i/upload/2023/11/06/20231106020013-107e390b-me.png"
            height="100%"
            className="ninosJugando"
            style={{
              objectFit: "contain",
              position: "absolute",
              maxWidth: 400,
            }}
            alt="Banner principal"
          />
        </Grid>
        {/* Banner principal kids */}

        {/* Banner historia kids */}
        <Grid
          item
          xs={12}
          container
          id="historiadelchocolate"
          minHeight="90vh"
          alignItems="center"
          justifyContent={{ xs: "center", lg: "end" }}
          spacing={2}
        >
          <Grid item xs={12} lg={6} padding={{ xs: 0, md: 10 }}>
            <img
              src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/06/20231106031107-6020bc85.png"
              width="100%"
              alt="boton rojo"
              onClick={() => alert("holi")}
              style={{
                maxWidth: 800,
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} container justifyContent="center">
            <iframe
              width="700"
              height="400"
              src="https://www.youtube.com/embed/phD1F0Xpvb8?si=dnkOGIOPKUX63bOl"
              style={{ borderWidth: 25, borderColor: "#39C0CC" }}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Grid>
          <img
            src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/06/20231106031510-6c96afcd.png"
            height="100%"
            className="ninosJugando"
            style={{
              objectFit: "contain",
              position: "absolute",
              maxWidth: 750,
            }}
            alt="Banner principal"
          />
        </Grid>
        {/* Banner historis kids */}

        {/* Banner contacto kids */}
        <Grid
          item
          xs={12}
          container
          id="preguntaleateo"
          minHeight="90vh"
          alignItems="center"
          justifyContent={{ xs: "center", lg: "start" }}
          spacing={2}
        >
          <Grid
            item
            container
            justifyContent={{ xs: "center", lg: "flex-end" }}
            paddingRight={{ xs: 0, lg: 6 }}
            xs={12}
            marginTop={{ xs: 0, lg: 12 }}
            zIndex={99}
            spacing={2}
          >
            <img
              src="https://imagenturistica.tabasco.gob.mx/upload/2023/11/06/20231106035931-8f89b777.png"
              height="100%"
              className="ninosNoJugando"
              style={{
                objectFit: "contain",
                maxWidth: 400,
              }}
              alt="Banner principal"
            />
            <Grid item xs={12} lg={8}>
              <TextField
                fullWidth
                variant="outlined"
                label="CORREO ELECTRÓNICO:"
                className="chocolate-kids"
                sx={{ border: 10, borderColor: "#F16B21", borderRadius: 5 }}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <TextField
                fullWidth
                multiline
                maxRows={5}
                label="MENSAJE:"
                className="chocolate-kids"
                sx={{
                  border: 10,
                  borderColor: "#FADC01",
                  borderRadius: 5,
                  height: 200,
                }}
              />
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" lg={8}>
              <Button
                variant="contained"
                size="large"
                color="success"
                href={`mailto:hola@festivaldelchocolate.mx`}
              >
                ENVIAR
              </Button>
            </Grid>
          </Grid>

          <img
            src="https://imagenturistica.tabasco.gob.mx/i.php?/upload/2023/11/06/20231106032658-18e012a6-xx.png"
            height="100%"
            className="ninosJugando"
            style={{
              objectFit: "contain",
              position: "absolute",
              maxWidth: 1920,
            }}
            alt="Banner principal"
          />
        </Grid>
        {/* Banner contacto kids */}
      </Grid>
      <Footer />
    </Container>
  );
};

export default KidsZone;
